<template>
  <div>
    <hakakses-list-add-new
      :is-add-new-hakakses-sidebar-active.sync="isAddNewHakaksesSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!--hakakses-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /-->
    <!-- <b-card v-if="session == 0"
      no-body
      class="mb-0"
    >
      <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          Tidak bisa diakses
        </b-col>
      </b-row>
      </div>
    </b-card> -->

    <!-- Table Container Card -->
    <b-card v-if="session > 0"
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'apps-hak-akses-add'}"
              >
                <span class="text-nowrap">Tambah Hak Akses</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refHakaksesListTable"
        class="position-relative"
        :items="fetchHakakses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: hakakses -->
        <template #cell(hakakses)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveHakaksesRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-hakakses-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-hakakses-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveHakaksesRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveHakaksesRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveHakaksesStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-hak-akses-edit', params: { hakakses_name: data.item.hakakses_name } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMsgBoxTwo(data.item.hakakses_name)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalHakakses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <template #code>
        {{ codeMessageBox }}
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// buat ambil api
import axios from '@axios'
// buat nampil data
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HakaksesListFilters from './HakaksesListFilters.vue'
import useHakaksesList from './useHakaksesList'
import hakaksesStoreModule from '../hakaksesStoreModule'
import HakaksesListAddNew from './HakaksesListAddNew.vue'
import { codeMessageBox } from './code'
import { codeVariant } from './codetoast'
import useAppConfig from '@core/app-config/useAppConfig'
import router from '@/router'
// import {useRoute} from 'vue-router'

export default {
  components: {
    HakaksesListFilters,
    HakaksesListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      boxOne: '',
      boxTwo: '',
      codeMessageBox,
    }
  },
  setup() {
    // const route = useRoute()
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const HAKAKSES_APP_STORE_MODULE_NAME = 'app-hakakses'

    // Register module
    if (!store.hasModule(HAKAKSES_APP_STORE_MODULE_NAME)) store.registerModule(HAKAKSES_APP_STORE_MODULE_NAME, hakaksesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HAKAKSES_APP_STORE_MODULE_NAME)) store.unregisterModule(HAKAKSES_APP_STORE_MODULE_NAME)
    })

    const roleOptions = ref([])
    const isAddNewHakaksesSidebarActive = ref(false)

    const planOptions = [
      { label: 'SMO', value: 'SMO' },
      { label: 'User', value: 'User' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchHakakses,
      tableColumns,
      perPage,
      currentPage,
      totalHakakses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHakaksesListTable,
      refetchData,

      // UI
      resolveHakaksesRoleVariant,
      resolveHakaksesRoleIcon,
      resolveHakaksesStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useHakaksesList()

    return {
      session,
      // Sidebar
      isAddNewHakaksesSidebarActive,

      fetchHakakses,
      tableColumns,
      perPage,
      currentPage,
      totalHakakses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refHakaksesListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveHakaksesRoleVariant,
      resolveHakaksesRoleIcon,
      resolveHakaksesStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async showMsgBoxTwo(id) {
      this.boxTwo = id
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',

          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete_hak_akses')
            params.append('hakakses_name', id)
            const result = await axios.post(`${useAppConfig().AppUrl}hakakses/delete`, params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
